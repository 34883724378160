//去除touch事件谷歌提示
(function () {
  if (typeof EventTarget !== "undefined") {
    let func = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, fn, capture) {
      if(!this){
        return
      }
      this.func = func;
      if (typeof capture !== "boolean") {
        capture = capture || {};
        capture.passive = false;
      }
      this.func(type, fn, capture);
    };
  };
}());
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/util/prototype"//Vue prototype自定义封装方法
import ECharts from "echarts";
import china from 'echarts/map/json/china.json'
ECharts.registerMap('china', china);
Vue.prototype.$EC = ECharts
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(ElementUI).use(VueAxios,axios);
Vue.component('ETable',()=>import('./components/myTable/table'))
Vue.component('EUpload',()=>import('./components/myUpload/upload'))
import myApi from "./util/myApi";
Vue.prototype.$iApi = myApi
// 初始化客户端
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
