import ajax from 'axios'
import store from '../store/index'
const axios = ajax.create()
import {Message,Loading} from "element-ui";
axios.defaults.baseURL='/test'
axios.interceptors.response.use(res =>{
    if(res.headers.token){
        localStorage.setItem('PAAS_TOKEN',JSON.stringify(res.headers.token))
    }
    if(res.data.code==1000||res.data.code==200){
        return res.data
    }else{
        if(res.data.message){
            // Message.error(res.data.message)
        }else{
            Message.error('服务器异常')
        }
        return res.data
        // return Promise.reject(res.data)
        // code不符合时res只抛出不返回
    }
},err=>{
    Loading.service({}).close()//全局关闭加载遮罩
    if(err.response.headers.token){
        localStorage.setItem('PAAS_TOKEN',JSON.stringify(err.response.headers.token))
    }
    if(err.response.data.status==888||err.response.data.status==999){
        Message.error('身份过期，请重新登录')
        return store.commit('tokenTimeOut')//退出登录
    }
    Message.error('服务器异常')
    return Promise.reject(err)
})
const request = function ({url,method,params,data,responseType}) {
    let TOKEN
    if(localStorage.getItem('paas_loginInfo')){
        if(localStorage.getItem('PAAS_TOKEN')||JSON.parse(localStorage.getItem('paas_loginInfo')).token){
            TOKEN = localStorage.getItem('PAAS_TOKEN')||JSON.parse(localStorage.getItem('paas_loginInfo')).token
            TOKEN=TOKEN.substring(1,TOKEN.length-1)//去除双引号
        }
    }
    if(TOKEN){
        // console.log('开启全局请求头')
        axios.defaults.headers.common['token']=TOKEN;
    }else{
        delete axios.defaults.headers.common['token'];
    }
    return axios({
        url,
        method,
        params,
        data,
        responseType,
        timeout:30000
    })
}

export default request
