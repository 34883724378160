import Router from 'vue-router'
import router from "./index"; //原路由对象
const routes = [
    // 工作台
    {
        path: "/",
        redirect: "/worktable",
    },
    //登录页
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login"),
        meta:{
            title:'登录'
        }
    },
    {
        path: '*',
        name:'404',
        component:()=>import('../views/notFount'),
        meta: {
            title: '404'
        }
    },
];
const createRouter = ()=> new Router({
    mode: 'history',
    routes
})

export function resetRouter (obj) {
    // 用初始化的matcher替换当前router的matcher
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}
