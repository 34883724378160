import request from "../util/require";
import upload from '../util/upload';//本地需要测试上传接口时切换
import qs from 'qs'
/**
 * 登录接口
 * @param obj
 * @returns {Promise<AxiosResponse<any>>}
 */
export function login(obj) {
    return   request({
        url:'/paasbase/system/user/login',method:'post',data:obj,
    })
}

/**
 * 登录后初始化数据获取
 * @returns {Promise<AxiosResponse<any>>}
 */
export function afterGetData() {
    return request({
        url:'/paasbase/system/dictionary/init/get',method:'post',
    })
}
/**
 * 获取环信登录所需信息
 * @param v
 * @returns {Promise<AxiosResponse<any>>}
 */
export function webIMInfo(v) {
    return   request({
        url:'/admin/get_user',method:'post',params: {userName:v}
    })
}
/**
 * 获取所有字典
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getDict(){//获取所有字典信息接口
    return request({
        url:'/dict/getDict',
        method:'GET',
    })
}

/**
 * 上传接口
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadApi(file){//
    return request({
        url:'/upload/img',
        method:'post',
        data:file,
        headers:{
            //文件上传用该formdata类型请求头
            'Content-Type': 'multipart/form-data'
        }
    })
}

/**
 * 上传证书接口
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadCRTApi(file){//
    return request({
        url:'/upload/certificate',
        method:'post',
        data:file,
        headers:{
            //文件上传用该formdata类型请求头
            'Content-Type': 'multipart/form-data'
        }
    })
}

/**
 * 开通法大大/去认证
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pcToAuthenticate(id) {
    return request({
        url:'/admin/init/user/certification',
        method:'post',
        data:{id}
    })
}