import Vue from 'vue'
import router from "@/router";
import {Loading, MessageBox} from "element-ui";
import sw from "element-ui/src/locale/lang/sw";
//全局状态颜色使用
Vue.prototype.$color=(num)=>{
    num=Number(num)
    switch (num) {
        case 2:
            return 'rgba(255, 141, 26, 1)'//已驳回
            break;
        case 1:
            return 'rgba(67, 207, 124, 1)'//已审核
            break;
        case 0:
            return 'rgba(255, 28, 28, 1)'//待审核
            break;
        default:return 'rgba(67, 207, 124, 1)'
    }
}
//二次封装element 开启遮罩
Vue.prototype.$ELoading =(num,text='')=>{
    Loading.service({
        lock: true,
        text,
        spinner: 'el-icon-loading',
        background: `rgba(0, 0, 0, ${num?num:0.5})`
    });
}
//二次封装element 关闭遮罩
Vue.prototype.$EClose =()=>{
    Loading.service({}).close();
}
//二次封装element 删除警告弹窗  '确认删除后无法恢复，确认删除请点击确定即可删除xx。', '确认要删除此xx吗？'
Vue.prototype.$EConfirm=(message, title, type)=>{
    return MessageBox.confirm(message||'确认删除后无法恢复，确认删除请点击确定即可删除该条数据。', title||'确认删除该条数据吗？', {
        cancelButtonClass:'cancelButtonClass',
        confirmButtonClass:'confirmButtonClass',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose:false,
        type: type||'error',
        customClass:'ptClass',
        center:true
    })
}
//请求参数空值过滤处理方法
Vue.prototype.$req=(data,deep=false)=>{
    const req = JSON.parse(JSON.stringify(data)) //防止指针指向相同空间
    for (let reqKey in req) {
        if(req[reqKey]===''||req[reqKey]===null||req[reqKey]===undefined||JSON.stringify(req[reqKey])=='{}'||JSON.stringify(req[reqKey])=='[]'){
            delete req[reqKey]
        }else{
            if(req[reqKey] instanceof Object){
                for (const reqKeyKey in req[reqKey]) {
                    if(req[reqKey][reqKeyKey]===''||req[reqKey][reqKeyKey]===null||req[reqKey][reqKeyKey]===undefined||req[reqKey][reqKeyKey].length==0){
                        delete req[reqKey][reqKeyKey]
                    }
                    //data包delete
                    if(deep&&JSON.stringify(req[reqKey])=='{}'){
                      delete req[reqKey]
                    }
                }
            }
        }
    }
    return req
}
//本地临时缓存存储方法
Vue.prototype.$sessionSet=(data)=>{
    sessionStorage.setItem('YM_Query',JSON.stringify(data))
    return 'sessionStorage存储成功'
}
//本地临时缓存读取方法
Vue.prototype.$sessionGet=()=>{
    return JSON.parse(sessionStorage.getItem('YM_Query'))
}
//清除本地临时缓存方法
Vue.prototype.$sessionRemove=()=>{
    return sessionStorage.removeItem('YM_Query')
}
//用户信息读取
Vue.prototype.$userGet=()=>{
    return localStorage.getItem('YM_INFO')&&JSON.parse(localStorage.getItem('YM_INFO')).userLogin?
        JSON.parse(localStorage.getItem('YM_INFO')).userLogin:JSON.parse(localStorage.getItem('YM_INFO')).paasuserManager
}
//路由跳转标签页缓存
Vue.prototype.$routerSetTag=(path,tag)=>{
    if(!path)return
    router.push({path,query:{tag}})
}
//路由返回父级标签页
Vue.prototype.$routerReturnTag=(path)=>{
    if(!path)return
    return {path,query:{tag:router.history.current.query.tag}}
}
//路由跳转标签页缓存读取
Vue.prototype.$routerGetTag=(tag)=>{
    if(router.history.current.query&&router.history.current.query.tag){
        tag=router.history.current.query.tag
    }
    return tag
}
//路由历史删除
Vue.prototype.$routeReplaceTag=(path)=>{
    if(!path)return
    router.replace({path,query:{ed:true}})
}
Vue.prototype.$routeReplaceTrue=(path)=>{
    return router.app.$route.query.ed
}
//默认头像
Vue.prototype.$defaultHeadUrl=()=>{
    return 'https://testfile.youmijob.com/images/20231024/f485a0908eac2d68101ab23d0f70a0b.png'
}
