<template>
  <div id="app">
    <div class="center">
      <router-view />
    </div>
  </div>
</template>
<script>
  export default {
    name: "App",
    components: {
    },
    created(){
      if(localStorage.getItem('user')){
        this.$store.commit('userSave',JSON.parse(localStorage.getItem('user')))
      }
    },
    methods:{

    }
  };
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;

}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
.center {
  width: 100%;
  height: calc(100vh);
}
.tableBox>.scroll{
  width: 100%;
  height: calc(100% - 52px);
  overflow: auto;
}
#nprogress .bar {
  background: blue !important;
}
.myTooltip{
  max-width: 50%;
  max-height:50%;
  overflow-y: auto;
}
/*E confirm样式*/
.ptClass {
  padding: 0 10px;
  padding-bottom: 20px !important;
}
.ptClass .el-message-box__title {
  align-items: center;
  justify-content: flex-start !important;
}
.ptClass .el-message-box__title  span {
  font-weight: 500;
}
.ptClass .el-message-box__status.el-icon-error {
  color: rgba(255, 87, 51, 1);
}

.ptClass .el-message-box__message {
  text-align: left;
  padding-left: 18px;
}

.ptClass .el-message-box__btns {
  margin-top: 5px;
}

.ptClass .cancelButtonClass {
  border-radius: 5px !important;
  background: linear-gradient(90deg, rgba(173, 211, 255, 0.67) 0%, rgba(255, 255, 255, 0) 100%) !important;
  border: 1px solid rgba(31, 120, 255, 1) !important;
  font-size: 12px;
  font-weight: 500;
  color: rgba(31, 120, 255, 1);
  padding: 10px 30px;
}

.ptClass .confirmButtonClass {
  border-radius: 5px !important;
  background: rgba(50, 135, 255, 1) !important;
  border: 1px solid rgba(31, 120, 255, 1) !important;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  padding: 10px 30px;
  margin-left: 20px !important;
}

.ptClass .confirmButtonClass:hover {
  background: #409EFF !important;
}

.ptClass .fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.ptClass .fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
<!--表格公共bug-css-->
<style>
  /*//显示滚动条*/
.el-table__body-wrapper {
    overflow: auto !important;
    position: relative;
  }
</style>
<!--顶部导航栏公共类-->
<style>
  .el-menu {
    height: 100%;
  }
  .el-menu-item-group > ul {
    display: flex;
    flex-wrap: wrap;
  }
  .el-submenu .el-menu-item {
    width: 100% !important;
    font-size: 14px;
    text-align: left;
    padding-left: 50px !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #fff !important;
  }
</style>
<!--左侧导航栏公共类-->
<style>
 .aside .el-submenu__title{
    font-size: 16px !important;
  }
 .aside .el-menu-item-group__title{
   padding: 0 !important;
 }
</style>
<style>
  .is-active{
    color:#031ef3 !important;
    font-weight: 900;
  }
  .is-active .el-submenu__title{
    color:#031ef3 !important;
    font-weight: 900;
  }

  .is-active .el-submenu__title i{
    color: #031ef3;
    font-weight: 900;
  }
</style>
<style>
<!--  表格标题行末小bug  -->
    ::v-deep.el-table th.el-table__cell {
        background: #F5F6FA;
    }
.el-table__row{
  height: 55px;
  min-height: 55px;
}
</style>
<!--一级路由公共布局css-->
<style>
    .home {
        height: 100% !important;
    }
    .el-container {
        height: 100% !important;
    }
    .el-aside {
        height: 100% !important;
    }
    .hello .el-menu {
        padding-left: 238px !important;
    }

    .el-main {
        height: calc(100vh - 64px) !important;
        background: #f5f6fa !important;
    }
    .el-card__body,
    .el-main {
        padding: 0 !important;
    }
    .el-header {
        border-bottom: 1px solid #e6e6e6 !important;
    }
</style>
<!--四级路由公共css-->
<style>
 .el-menu--horizontal > .el-menu-item.is-active{
    border-bottom: 3px solid rgba(42, 85, 229, 1) !important;
  }
 .el-menu--horizontal > .el-menu-item{
   height: 100% !important;
 }
</style>